








































import { CheckOutSlot, TimeLog } from '@/types/intervals'
import { Vue, Component, Prop } from 'vue-property-decorator'
import gql from 'graphql-tag'

@Component
export default class extends Vue {
  @Prop() readonly closed!: () => void
  @Prop(Number) readonly userId!: number
  @Prop() readonly update!: (log: Partial<TimeLog>) => void
  private selectedDate = new Date().toISOString().slice(0, 10)
  private checkInTime = '0000'
  private checkOutTime = '0000'
  private rules = {
    match: (v: string) => {
      const reg = /\d{4}/
      return (reg.test(v) && v.length === 4) || 'Time must in "1212" format'
    },
  }
  private onUpdate = false

  private get canAdd() {
    const reg = /\d{4}/
    return (
      reg.test(this.checkInTime) &&
      this.checkInTime.length === 4 &&
      reg.test(this.checkOutTime) &&
      this.checkOutTime.length === 4
    )
  }

  private calculateSlotIndex(date: Date): number {
    if (!date) return -1
    const slotTime = [
      [0, 10],
      [10, 12],
      [12, 15],
      [15, 17],
      [17, 19],
      [19, 21],
      [21, 23],
      [23, 24],
    ]
    return slotTime.findIndex(
      o => o[0] <= date.getHours() && date.getHours() < o[1]
    )
  }

  private async addNewTimeLog() {
    this.onUpdate = true
    const [year, month, day] = this.selectedDate.split('-')
    const chkIn = new Date(
      +year,
      +month - 1,
      +day,
      +this.checkInTime.slice(0, 2),
      +this.checkInTime.slice(-2)
    )
    const chkOut = new Date(
      +year,
      +month - 1,
      +day,
      +this.checkOutTime.slice(0, 2),
      +this.checkOutTime.slice(-2)
    )
    const startSlot = this.calculateSlotIndex(chkIn)
    const endSlot = this.calculateSlotIndex(chkOut)
    const slots: string[] = []
    for (let i = startSlot; i <= endSlot; i++) {
      slots.push(CheckOutSlot.FullTime)
    }
    try {
      const { data } = await this.$apollo.mutate<{
        addTimeLog: Partial<TimeLog>
      }>({
        mutation: gql`
          mutation AddNewTimeLog($data: CreateTimeLogInput!) {
            addTimeLog(data: $data) {
              id
              checkInTime
              checkOutTime
              slots
              workHour
            }
          }
        `,
        variables: {
          data: {
            userId: this.userId,
            checkInTime: chkIn.toISOString(),
            checkOutTime: chkOut.toISOString(),
            slots: slots,
          },
        },
      })
      if (data) {
        this.update(data.addTimeLog)
      }
      // const { data } = await this.$apollo.query({
      //   query: gql`
      //     query TestQuery {
      //       timeLog(id: 20) {
      //         id
      //         checkInTime
      //         checkOutTime
      //         workHour
      //         slots
      //       }
      //     }
      //   `,
      // })
      // console.log(data)
    } catch (error) {
      // empty
    } finally {
      this.closed()
      this.onUpdate = false
    }
  }
}
