












































import { Vue, Component } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { TimeLog, UserInterval } from '@/types/intervals'
import TimelogComponent from '@/components/TimeLogManagement/Timelog.vue'
import AddTimelogComponent from '@/components/TimeLogManagement/AddTimelog.vue'

@Component({
  components: {
    TimelogComponent,
    AddTimelogComponent,
  },
})
export default class extends Vue {
  private onFetch = true
  private userIntervalId = 0
  private userId = 0
  private workHour = 0
  private timeLogs: Partial<TimeLog>[] = []
  private headers = [
    { text: 'Date', value: 'date', align: 'center' },
    { text: 'Check-in', value: 'checkInTime', align: 'center' },
    { text: 'Check-out', value: 'checkOutTime', align: 'center' },
    { text: '8-10', value: '8-10', align: 'center' },
    { text: '10-12', value: '10-12', align: 'center' },
    { text: '13-15', value: '13-15', align: 'center' },
    { text: '15-17', value: '15-17', align: 'center' },
    { text: '17-19', value: '17-19', align: 'center' },
    { text: '19-21', value: '19-21', align: 'center' },
    { text: '21-23', value: '21-23', align: 'center' },
    { text: '23-24', value: '23-24', align: 'center' },
    { text: 'WH', value: 'workHour', align: 'center' },
    { text: 'CR', value: 'courseHour', align: 'center' },
    { text: 'Action', value: 'action', align: 'center' },
  ]
  private addDialog = false

  mounted() {
    const params = this.$route.params
    if (params.userIntervalId && params.userId) {
      this.userIntervalId = +params.userIntervalId
      this.userId = +params.userId
      this.fetchData()
    }
  }

  private get totalCourseHour() {
    let total = 0
    for (const i of this.timeLogs) {
      const cr = i.courseHour
      if (cr) {
        total += cr
      }
    }
    return total
  }

  private closeAddDialog() {
    this.addDialog = false
  }

  private updateTimeLogs(log: Partial<TimeLog>) {
    const temp = [...this.timeLogs, log]
    this.timeLogs = temp.sort((a, b) => {
      const timeA = a.checkInTime ? new Date(a.checkInTime).getTime() : 0
      const timeB = b.checkInTime ? new Date(b.checkInTime).getTime() : 0
      return timeA - timeB
    })
  }

  private deleteTimeLog(logId: number) {
    const deleted = this.timeLogs.findIndex(el => el.id === logId)
    if (deleted >= 0) {
      this.timeLogs.splice(deleted, 1)
    }
  }

  private updateTimeLog(log: Partial<TimeLog>) {
    const thisLog = this.timeLogs.find(el => el.id === log.id)
    if (thisLog) {
      thisLog.checkInTime = log.checkInTime
      thisLog.checkOutTime = log.checkOutTime
      thisLog.slots = log.slots
      thisLog.workHour = log.workHour
    }
  }

  private async fetchData() {
    this.onFetch = true
    try {
      const { data } = await this.$apollo.query<{
        userInterval: Partial<UserInterval>
      }>({
        query: gql`
          query FetchUserIntervalTimelogs($id: Float!) {
            userInterval(id: $id) {
              id
              workHour
              timeLogs {
                id
                checkInTime
                checkOutTime
                slots
                workHour
                courseHour
              }
            }
          }
        `,
        variables: {
          id: this.userIntervalId,
        },
        fetchPolicy: 'no-cache',
      })
      if (data) {
        this.workHour = data.userInterval.workHour || 0
        this.timeLogs = data.userInterval.timeLogs || []
      }
    } catch (error) {
      // empty
    } finally {
      this.onFetch = false
    }
  }
}
