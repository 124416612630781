




















































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { CheckOutSlot, TimeLog } from '@/types/intervals'
import { format } from 'date-fns'
import gql from 'graphql-tag'

@Component
export default class extends Vue {
  @Prop() readonly timelog!: Partial<TimeLog>
  @Prop() readonly deleteFunc!: (id: number) => void
  @Prop() readonly updateFunc!: (log: Partial<TimeLog>) => void
  private onUpdate = false
  private onEdit = false
  private editMenu = false
  private editDate = new Date().toISOString().slice(0, 10)
  private editChkIn = '0000'
  private editChkOut = '0000'
  private editSlot = ['-', '-', '-', '-', '-', '-', '-', '-']
  private slotType = ['-', ...Object.values(CheckOutSlot)]

  private prepareData() {
    const chkIn = this.timelog.checkInTime
      ? new Date(this.timelog.checkInTime)
      : new Date()
    const chkOut = this.timelog.checkOutTime
      ? new Date(this.timelog.checkOutTime)
      : new Date()
    this.editDate = chkIn.toISOString().slice(0, 10)
    this.editChkIn = format(chkIn, 'HHmm')
    this.editChkOut = format(chkOut, 'HHmm')
    this.editSlot = this.fixedSizeArr(
      this.timelog.checkInTime || '',
      this.timelog.slots || []
    )
  }

  private formatDate(dateStr: string) {
    const date = new Date(dateStr)
    return format(date, 'iii dd/MM/yy')
  }

  private formatTime(dateStr: string) {
    const date = new Date(dateStr)
    return format(date, 'HH:mm')
  }

  private calculateSlotIndex(date: Date): number {
    if (!date) return -1
    const slotTime = [
      [0, 10],
      [10, 12],
      [12, 15],
      [15, 17],
      [17, 19],
      [19, 21],
      [21, 23],
      [23, 24],
    ]
    return slotTime.findIndex(
      o => o[0] <= date.getHours() && date.getHours() < o[1]
    )
  }

  private fixedSizeArr(date: string, strArr: string[] | null) {
    const slots = strArr || []
    const startIndex = this.calculateSlotIndex(new Date(date))
    const arr = ['-', '-', '-', '-', '-', '-', '-', '-']
    const newArr = [...arr.slice(0, startIndex), ...slots, ...arr]
    return newArr.slice(0, 8)
  }

  private btnColor(val: CheckOutSlot) {
    switch (val) {
      case CheckOutSlot.FullTime:
        return 'blue lighten-3'

      case CheckOutSlot.Hybrid:
        return 'green lighten-3'

      case CheckOutSlot.Meeting:
        return 'red lighten-3'

      case CheckOutSlot.Sheet:
        return 'purple lighten-3'

      case CheckOutSlot.Admin:
        return 'brown lighten-3'

      case CheckOutSlot.Course:
        return 'orange lighten-3'

      case CheckOutSlot.Computer:
        return 'cyan lighten-3'

      case CheckOutSlot.Design:
        return 'teal lighten-3'

      case CheckOutSlot.Skill:
        return 'indogo lighten-3'

      case CheckOutSlot.Study:
        return 'grey'

      case CheckOutSlot.OutsideWorkSupport:
        return 'amber lighten-1'
      default:
        return 'grey lighten-2'
    }
  }

  private async deleteTimeLog() {
    this.onUpdate = true
    try {
      const { data } = await this.$apollo.mutate<{
        deleteTimeLog: { deletedId: number }
      }>({
        mutation: gql`
          mutation DeleteThisTimeLog($id: Float!) {
            deleteTimeLog(id: $id) {
              deletedId
            }
          }
        `,
        variables: {
          id: this.timelog.id,
        },
      })
      if (data) {
        this.deleteFunc(data.deleteTimeLog.deletedId)
      }
    } catch (error) {
      // empty
    } finally {
      this.onUpdate = false
    }
  }

  private async editTimeLog() {
    const reg = /\d{4}/
    if (
      this.editChkIn.length === 4 &&
      reg.test(this.editChkIn) &&
      this.editChkOut.length === 4 &&
      reg.test(this.editChkOut)
    ) {
      this.onUpdate = true
      const [year, month, day] = this.editDate.split('-')
      const chkIn = new Date(
        +year,
        +month - 1,
        +day,
        +this.editChkIn.slice(0, 2),
        +this.editChkIn.slice(-2)
      )
      const chkOut = new Date(
        +year,
        +month - 1,
        +day,
        +this.editChkOut.slice(0, 2),
        +this.editChkOut.slice(-2)
      )
      const startIndex = this.calculateSlotIndex(chkIn)
      const endIndex = this.calculateSlotIndex(chkOut)
      const slots = this.editSlot.slice(startIndex, endIndex + 1)
      const fixSlots = slots.map(el => {
        if (el === '-') {
          return CheckOutSlot.FullTime
        } else {
          return el
        }
      })
      try {
        const { data } = await this.$apollo.mutate<{
          editTimeLog: Partial<TimeLog>
        }>({
          mutation: gql`
            mutation EditTimeSlotData($data: EditTimeLogInput!) {
              editTimeLog(data: $data) {
                id
                checkInTime
                checkOutTime
                slots
                workHour
              }
            }
          `,
          variables: {
            data: {
              id: this.timelog.id,
              checkInTime: chkIn,
              checkOutTime: chkOut,
              slots: fixSlots,
            },
          },
        })
        if (data) {
          this.updateFunc(data.editTimeLog)
        }
      } catch (error) {
        // empty
      } finally {
        this.onEdit = false
        this.onUpdate = false
      }
    }
  }
}
