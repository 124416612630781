import { User } from './user'

export interface Intervals {
  intervals: Partial<Interval>[]
}

export interface Interval {
  id: number
  name: string
  startDate: string
  endDate: string
  workDay: number
  userIntervals: Partial<UserInterval>[]
}

export interface UserInterval {
  id: number
  baseSalary: number
  extra: string
  position: string
  remark: string
  otMultiplier: number
  userId: number
  user: Partial<User>
  workHour: number
  courseHour: number
  timeLogs: Partial<TimeLog>[]
}

export interface TimeLog {
  id: number
  checkInTime: string
  checkOutTime: string
  slots: CheckOutSlot[]
  workHour: number
  courseHour: number
}

export enum CheckOutSlot {
  FullTime = 'FullTime',
  Hybrid = 'Hybrid',
  Meeting = 'Meeting',
  Sheet = 'Sheet',
  Admin = 'Admin',
  Course = 'Course',
  Computer = 'Computer',
  Design = 'Design',
  Skill = 'Skill',
  Study = 'Study',
  OutsideWorkSupport = 'OutsideWorkSupport',
}
